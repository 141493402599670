import './Sidebar.css'
import {  IoSettingsOutline, IoLogInOutline, IoLogOutOutline } from "react-icons/io5";
import { BiLogInCircle , BiLogOutCircle } from "react-icons/bi";
import { LiaRobotSolid } from "react-icons/lia";
import { RiContactsBookLine } from "react-icons/ri";
import { FaRobot,  FaRegUser  } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { Link, NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'


const Sidebar = () => {

  const url = process.env.REACT_APP_API_URL;

  const [user, setUser] = useState(null);
    const [botName, setBotName] = useState(null);
    const [botLastName, setBotLastName] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [bgcolor, setBgcolor] = useState(null);
    const [bgimg, setBgimg] = useState(null);
    const [namecolor, setNamecolor] = useState(null);

    const navigate = useNavigate();
    const handleSignOut = (e) => {
      e.preventDefault();
  
      auth
        .signOut()
        .then(() => {
          window.location.reload();
          navigate("/");
          setUser(null);
        })
        .catch((error) => {
          console.log("Sign out error:", error);
        });
    };
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((currentUser) => {
        if (currentUser) {
          setUser(currentUser);
        }
      });
  
      return () => unsubscribe();
    }, []);

    

    useEffect(() => {
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setBotName(data.bot_first_name);
          setBotLastName(data.bot_last_name)
          setAvatar(data.bot_avatar)
          setBgcolor(data.sidebar_bg_color)
          setBgimg(data.sidebar_bg_img)
          setNamecolor(data.bot_name_color)
        })
        .catch((error) => {
          console.error("Error fetching bot_name:", error);
        });
    }, []);
  
    
    return ( 
        <div className='ws' style={{backgroundImage: `url(${bgimg})` }}>
     
       
       <div className='top'>
       <div className='strip'>
       <h1></h1>
        <p className='head' style={{color: `${namecolor}`}}>{botName}<span className='dot'>.</span>{botLastName}</p> 
        </div>
        <div className='options'>
        <div className='sideoption'>  <NavLink to='/' > <AiOutlineHome  color='white'  className='icons'/><span className='oname'>&nbsp;&nbsp;&nbsp;&nbsp;Home Page</span></NavLink> </div>
       <div className='sideoption'> <a href='https://free.kreatebots.com/' target="_blank"><LiaRobotSolid color='white' className='icons'/><span className='oname'>&nbsp;&nbsp;&nbsp;&nbsp;Try for free</span></a> </div>
      <div className='sideoption'>  <NavLink to='/my-account' > <FaRegUser  color='white'  className='icons'/><span className='oname'>&nbsp;&nbsp;&nbsp;&nbsp;My Account</span></NavLink> </div>
       <div className='sideoption'> <div className='signin'>
        {user ? (
                  <div onClick={handleSignOut}><BiLogOutCircle color='white' className='icons'/><span className='oname'>&nbsp;&nbsp;&nbsp;&nbsp;Sign out</span></div>
                  ) : (
                    <Link to='/login'><BiLogInCircle color='white' className='icons'/><span className='oname'>&nbsp;&nbsp;&nbsp;&nbsp;Sign in</span></Link>
                  )}
        </div>
        </div>
        <div className='sideoption'> <NavLink to='/contact'><RiContactsBookLine color='white' className='icons'/><span className='oname'>&nbsp;&nbsp;&nbsp;&nbsp;Contact us</span></NavLink> </div>

        </div>
        

        </div>

        <img src={avatar} className="hero"></img>

        </div>

     );
}
 
export default Sidebar;