
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import config from './chatbot/bot/ChatBot/config.js'
import MessageParser from './chatbot/bot/ChatBot/MessageParser.js';
import ActionProvider from './chatbot/bot/ChatBot/ActionProvider.js';
import './App.css';
import Sidebar from './sidebar/Sidebar.js'
import Form from './Form/Form';
import {Route,BrowserRouter as Router, Routes} from 'react-router-dom' 
import Login from './pages/auth/login';
import Signup from './pages/auth/signUp';
import ResetPasswordPage from './pages/auth/forgotPassword'
import UserAnswersPage from './UserAnswersPage';
import Header from './header/Header.js';
import { FlagContext } from './FlagContext';
import { SessionContext } from './chatbot/bot/ChatBot/SessionContext.js';
import { useState, useEffect, useContext } from 'react';
import { auth } from './firebase.js';
import axios from "axios";
import { PiChats } from "react-icons/pi";
import { PiChatsBold } from "react-icons/pi";




const Main = () => {

  const url = process.env.REACT_APP_API_URL;
    let counter=0;
    const [user, setUser] = useState(null);
    const {ff} = useContext(SessionContext);
  const [sessionIds, setSessionIds] = useState([]);
  const [selectedSessionId, setSelectedSessionId] = useState("newChat");
  const [messages, setMessages] = useState([
    {
        "message": "hhh",
        "type": "user",
        "loading": false
    }
]);
  const [isLoading, setIsLoading] = useState(true);
  const { sessionID, setSessionID, compId, setCompId, prompt, setPrompt } = useContext(SessionContext);
  const [newChat, setNewChat] = useState(false);
  const [neww, setNeww] = useState(false);
  const [flag, setFlag] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [company, setCompany] = useState([]);
  const [selectedOption, setSelectedOption] = useState('Select Company');
  const [usermail, setUsermail] = useState("");
  const [uid, setUid] = useState("");
  const [systemPrompt, setSystemPrompt] = useState('');
  const [placeholders, setPlaceholders] = useState([]);

  const [companyName, setCompanyName] = useState('');
  const [companyLogo, setCompanyLogo] = useState(''); // State to store company logo URL


  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setCompanyName(data.company_name);
        setCompanyLogo(data.company_logo); // Set the company logo URL from JSON data
      })
      .catch((error) => {
        console.error("Error fetching bot_name:", error);
      });
  }, [url]);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setUsermail(currentUser.email)
        setUid(currentUser.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  const uploadFunction = (name, id) => {
    setSelectedOption(name);
    setCompanyId(id)
    setCompId(id);
  }
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
       
        const response = await axios.get(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/getCompaniesByUserId/${usermail}`);
        

        setCompany(response.data.companies)
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCompanies();


  }, [usermail]);

useEffect(() => {
  axios.get(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/placeholders/${companyId}`)
    .then(response => {
      if (response.status === 200) {
        setPlaceholders(response.data.data);
        console.log('Placeholders:', response.data.data);
      } else {
        console.error('Failed to fetch placeholders:', response.statusText);
      }
    })
    .catch(error => {
      console.error('Error fetching placeholders:', error.message);
    });
}, [companyId]);

useEffect(() => {
  axios.get(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/prompt/${companyId}`)
    .then(response => {
      if (response.status === 200) {
        setSystemPrompt(response.data.prompt.systemPrompt);
        console.log('System Prompt:', response.data.prompt.systemPrompt);
      } else {
        console.error('Failed to fetch system prompt:', response.statusText);
      }
    })
    .catch(error => {
      console.error('Error fetching system prompt:', error.message);
    });
}, [companyId]);

useEffect(() => {

  axios.get(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/user-answers/${uid}/${companyId}`)
  .then(response => {
    const answers = response.data.answers;
    console.log('User Answers:', answers);

    // const initialSystemPrompt = "You are a dietician and help people improve their health, fitness by giving guidance on what to eat. You are helping people working in Tech industry in India. User is a {age} years old {sex} and weighs {weight}. User's goal is to {goal}. Help user determine {userPrompt}.";

    if (placeholders.length > 0) {
      let updatedPrompt = systemPrompt;
      placeholders.forEach(({ question, placeholder }) => {
        const answerObj = answers.find(ans => ans.question === question);
        if (answerObj) {
          updatedPrompt = updatedPrompt.replace(new RegExp(placeholder, 'g'), answerObj.answer);
        }
      });
      console.log('Updated Prompt:');
      console.log(updatedPrompt);
      setPrompt(updatedPrompt); // Update the state with the updated prompt
    } else {
      console.error('Placeholders not fetched or empty.');
    }
  })
  .catch(error => {
    console.error('Error fetching user answers:', error);
    // Handle errors if necessary
  });
}, [companyId, placeholders, systemPrompt, uid, setPrompt]);



  const func = async () => {
    if (user) {
      try {
        const response = await axios.post(
          "https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/createSession",
          { userEmail: user.email }
        );
        setSessionID(response.data.sessionID);
      } catch (error) {
        console.error("Error creating session:", error);
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {

  
    if(neww){
      const handleSessionCreation = async () => {
        if (user) {
          try {
            const response = await axios.post(
              "https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/createSession",
              { userEmail: user.email }
            );
            setSessionID(response.data.sessionID);
          } catch (error) {
            console.error("Error creating session:", error);
          }
        }
      };
  
      handleSessionCreation(); 
    }
// Invoke session creation
  }, [user, setSessionID]);


  useEffect(() => {
    const fetchSessionIds = async () => {
      try {
        const response = await fetch(
          `https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/sessions/${user.email}`
        );
        const data = await response.json();
        if (data && data.sessions) {
          const ids = data.sessions.map((session) => session._id);
          setSessionIds(ids);
        }
      } catch (error) {
        console.error("Error fetching session IDs:", error);
      }
    };

    fetchSessionIds();
  }, [user]);


  const loadMessages = async (sessionId) => {
    try {
      const response = await fetch(
        `https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/sessions/${user.email}`
      );
      const data = await response.json();
      const specificSession = data.sessions.find(
        (session) => session._id === sessionId
      );
      console.log(specificSession.messages)
      setMessages(specificSession.messages);
      setIsLoading(false);
    } catch (error) {
      console.error("Error loading messages:", error);
    }
  };

  const reloadBot = async () => {
    setMessages([]);
    setNewChat(true);
    setIsLoading(false);
 };

 
  const handleSessionChange = async (event) => {
    const selectedId = event.target.value;
    setSelectedSessionId(selectedId);
    setIsLoading(true);

    if (selectedId === "newChat") {
      counter = counter + 1;
      console.log(counter);
      setSelectedSessionId(null);
      setMessages([]); // Clear messages directly here
      await reloadBot();
    } else {
      setNewChat(false);
      loadMessages(selectedId);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const url = 'https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/prompt/fitfusion';

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          // Handle the data received from the GET request
          console.log('Data received:', response.data.prompt.systemPrompt);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error scenario
      }
    };

    // Call the fetchData function
    fetchData();
  }, []); 

  
  const handleNewChatClick = () => {
    window.location.reload(); // Reload the current page
  };

    return ( <>
    <FlagContext.Provider value={{ flag, setFlag, companyId }}>
        <Router>
    <div style={{display:"flex"}}>
      <Sidebar/>
    <Header/>
{/* / */}
    </div>

    <Routes>
  
    
                <Route exact path='/' element={
                <>


<br/>
<br/>

{user ?  (<Chatbot
            className="cb"
            id='cbt'
            config={config}
            messageParser={MessageParser}
            placeholderText='Input placeholder'
            actionProvider={ActionProvider}
          />) : (        <>
            <div style={{ display: "flex", marginLeft:'45%', marginTop:'10%' }} className='brandname'>
              {companyLogo && <img src={companyLogo} style={{ borderRadius: "10px", width: "3vw" }} alt="Company Logo" />}
              &nbsp;
              <h1 style={{ fontWeight: "40px" }}>&nbsp;{companyName}</h1>

            </div>

          </>) }
               
          </>
          
          }/>
          
          <Route path="forgot_password" element={<ResetPasswordPage />} />
<Route path="/login" element={<Login />} />
<Route path="/signup" element={<Signup />} />
<Route path='/my-account' element={<Form/>}/>
</Routes>

{/* <UserAnswersPage/> */}
</Router>
</FlagContext.Provider>
    </> );
}
 
export default Main;