import './App.css';

import { SessionProvider } from "./chatbot/bot/ChatBot/SessionContext.js";
import Main from './Main.js';

function App() {


  return (
    <SessionProvider>
   <Main/>
</SessionProvider>
  );
}

export default App;
