import React from 'react';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './Chatbot.css'
import { SessionContext } from "./SessionContext.js";
import { auth } from '../../../firebase.js';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const [user, setUser] = useState(null);
  const { sessionID, compId,prompt, setPrompt  } = useContext(SessionContext);
  const [isLoading, setIsLoading] = useState(false);
  const [copyText,setCopyText]=useState('')
  const [limit,setLimit] = useState(40)
  const [fprompt, setFprompt] = useState('');
  const [uid, setUid] = useState("");
  const [usermail, setUsermail] = useState("");


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      setUsermail(currentUser.email);
      setUid(currentUser.uid);
    });
  
    return () => unsubscribe();
  }, []);
  


  const handleCopy=()=>{
      navigator.clipboard.writeText(copyText)
  }

  const askAction = async (msg) => {

    
      let replacedPrompt = prompt;
      replacedPrompt = replacedPrompt.replace(/{userPrompt}/g, msg);
    // alert(replacedPrompt)
      setFprompt(replacedPrompt);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", usermail);
    formData.append("filename", compId);
    formData.append("id", uid);
    formData.append("prompt", replacedPrompt);
    axios.post("https://openai-gemini-chatbot-api-5wz7dep6ya-uc.a.run.app/openai", formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  .then((response) => {
    setIsLoading(false);
    const message = createChatBotMessage(response.data.answer, {
      widget:'review',
    });
    setCopyText(response.data.answer)
    saver(message);
    updateState(message);
  })
  .catch((error) => {
    console.error('Error', error);
  });
  };


  const updateState = (message, checker) => {
    
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      checker,     
    }))    
  };

  const saver = async (message) => {
    await saveChatHistory({
      id: message.id,
      message: message.message,
      type: message.type,
      loading: message.loading,
      widget: message.widget,
    });
  };

  const saveChatHistory = async (obj) => {
    if (user && sessionID) {
      try {
        await axios.post(
          "https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/saveSession",
          {
            userEmail: user.email,
            sessionID,
            obj,
          }
        );
      } catch (error) {
        console.error("Error saving chat history:", error);
      }
    }
  };


  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleCopy,
            askAction,
          },
        });
      })}




<div className="range">
    <p className='ans'>Answer word limit</p>
    <input  type="range" min="0" max="100" step="10" value={limit} onChange={(e)=>setLimit(e.target.value)} style={{color:"yellow"}}/>
    <p>{limit}</p>
</div>


          
{isLoading && (
  <div class="shapes"></div>
)}


    </div>
  );
};

export default ActionProvider;

